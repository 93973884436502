import actions from './actions';

const {
    GET_ALL_MISSIONS,
    FILTER_MISSIONS,
    GET_UNINTERESTING_IDS,
    GET_MISSION_BY_ID,
    ADD_VIEWS,
    CLEAR_MISSION,
    MISSION_DETAILS,
    CLEAR_MISSION_DETAILS,
    MISSION_DETAILS_REQUEST_STATUS,
} = actions;

const initialState = {
    missions: null,
    mission: null,
    filteredMissions: null,
    addView: null,
    missionDetails: {
        isPending: false,
        isSuccess: false,
        isError: false,
        data: null,
    },
};

const MissionsReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_ALL_MISSIONS:
            return { ...state, missions: data };
        case FILTER_MISSIONS:
            return { ...state, filteredMissions: data };
        case GET_MISSION_BY_ID:
            return { ...state, mission: data };
        case ADD_VIEWS:
            return { ...state, addView: data };
        case CLEAR_MISSION:
            return { ...state, mission: null };
        case MISSION_DETAILS:
            return {
                ...state,
                missionDetails: {
                    ...state.missionDetails,
                    data: data,
                },
            };
        case MISSION_DETAILS_REQUEST_STATUS:
            return {
                ...state,
                missionDetails: {
                    ...state.missionDetails,
                    isPending: data.key === 'isPending' ? data.value : false,
                    isSuccess: data.key === 'isSuccess' ? data.value : false,
                    isError: data.key === 'isError' ? data.value : false,
                    data: data.key === 'isSuccess' && data.value ? state.missionDetails.data : null,
                },
            };
        case CLEAR_MISSION_DETAILS:
            return {
                ...state,
                missionDetails: {
                    isPending: false,
                    isSuccess: false,
                    isError: false,
                    data: null,
                },
            };
        default:
            return state;
    }
};
export { MissionsReducer };
