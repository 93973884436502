const actions = {
    GET_ALL_MISSIONS: 'GET_ALL_MISSIONS',
    FILTER_MISSIONS: 'FILTER_MISSIONS',
    GET_UNINTERESTING_IDS: 'GET_UNINTERESTING_IDS',
    GET_MISSION_BY_ID: 'GET_MISSION_BY_ID',
    ADD_VIEWS: 'ADD_VIEWS',
    CLEAR_MISSION: 'CLEAR_MISSION',
    MISSION_DETAILS: 'MISSION_DETAILS',
    CLEAR_MISSION_DETAILS: 'CLEAR_MISSION_DETAILS',
    MISSION_DETAILS_REQUEST_STATUS: 'MISSION_DETAILS_REQUEST_STATUS',

    missions: (data) => {
        return {
            type: actions.GET_ALL_MISSIONS,
            data,
        };
    },

    filterMissions: (data) => {
        return {
            type: actions.FILTER_MISSIONS,
            data,
        };
    },

    getMissionById: (data) => {
        return {
            type: actions.GET_MISSION_BY_ID,
            data,
        };
    },

    addViews: (data) => {
        return {
            type: actions.ADD_VIEWS,
            data,
        };
    },

    clearMission: () => {
        return {
            type: actions.CLEAR_MISSION,
            data: null,
        };
    },

    getMissionDetails: (data) => {
        return {
            type: actions.MISSION_DETAILS,
            data,
        };
    },

    clearMissionDetails: (data) => {
        return {
            type: actions.CLEAR_MISSION_DETAILS,
            data: null,
        };
    },

    setMissionDetailsRequestStatus: (data) => {
        return {
            type: actions.MISSION_DETAILS_REQUEST_STATUS,
            data,
        };
    },
};

export default actions;
