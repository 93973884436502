import actions from './actions';

const {
    STATISTIQUE,
    STATISTIQUE_ADS,
    ALL_MISSIONS,
    ALL_CONTACT,
    DETAIL_AD,
    DETAIL_ANNONCE,
    MISSION_LIST_REQUEST_STATUS,
} = actions;

const initialState = {
    stat: null,
    statAds: null,
    allMissions: null,
    allContact: null,
    detailAd: null,
    pinned: null,
    detailAnnonce: null,
    requestListStatus: {
        isPending: false,
        isSuccess: false,
        isError: false,
    },
};

const missionReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case DETAIL_ANNONCE:
            return { ...state, detailAnnonce: data };
        case STATISTIQUE:
            return { ...state, stat: data };
        case STATISTIQUE_ADS:
            return { ...state, statAds: data };
        case ALL_MISSIONS:
            return { ...state, allMissions: data };
        case ALL_CONTACT:
            return { ...state, allContact: data };
        case DETAIL_AD:
            return { ...state, detailAd: data };
        case MISSION_LIST_REQUEST_STATUS:
            return {
                ...state,
                requestListStatus: {
                    ...state.requestListStatus,
                    isPending: data.key === 'isPending' ? data.value : false,
                    isSuccess: data.key === 'isSuccess' ? data.value : false,
                    isError: data.key === 'isError' ? data.value : false,
                },
            };
        default:
            return state;
    }
};

export default missionReducer;
